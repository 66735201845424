.compactCalendar {
    $border-width: 2px;

    @define-mixin popup $content {
        &::before {
            border-color: transparent transparent #464646;
            border-style: solid;
            border-width: 10px;
            bottom: -15px;
            content: "";
            display: block;
            left: 50%;
            position: absolute;
            transform: translate(-50%, 0);
            z-index: 2;
        }

        &::after {
            background-color: #464646;
            border-radius: 5px;
            bottom: -45px;
            color: #fff;
            content: $(content);
            display: block;
            left: 50%;
            padding: 5px 10px;
            position: absolute;
            transform: translate(-50%, 0);
            white-space: nowrap;
            z-index: 2;
        }
    }

    @define-mixin start-item-background $item-background, $highlight-background {
        &:not(:nth-child(7n)) {
            position: relative;
            z-index: 1;

            &::before {
                background-color: $(highlight-background);
                bottom: -$border-width;
                content: "";
                display: block;
                height: calc(100% + 4px);
                position: absolute;
                right: -$border-width;
                top: -$border-width;
                width: 50%;
                z-index: -2;
            }

            &::after {
                background-color: $(item-background);
                border-radius: 50%;
                content: "";
                display: block;
                height: calc(100% + 4px);
                left: -$border-width;
                position: absolute;
                top: -$border-width;
                width: calc(100% + 4px);
                z-index: -1;
            }
        }
    }

    @define-mixin end-item-background $item-background, $highlight-background {
        &:not(:nth-child(7n + 1)) {
            position: relative;
            z-index: 1;

            &::before {
                background-color: $(highlight-background);
                bottom: -$border-width;
                content: "";
                display: block;
                height: calc(100% + 4px);
                left: -$border-width;
                position: absolute;
                top: -$border-width;
                width: 50%;
                z-index: -2;
            }

            &::after {
                background-color: $(item-background);
                border-radius: 50%;
                content: "";
                display: block;
                height: calc(100% + 4px);
                left: -$border-width;
                position: absolute;
                top: -$border-width;
                width: calc(100% + 4px);
                z-index: -1;
            }
        }
    }

    :global {
        --color-black: #191919;
        --color-licorice: #464646;
        --color-stone: #767676;
        --color-marble: #f6f8fb;
        --color-white: #f5f5f5;
        --color-milk: #fff;
        --color-blue-50: #e6f4ff;
        --color-blue-100: #e1edfe;
        --color-secondary-blue: #06befb;
        --color-primary-blue: #0063fb;
        --color-bluegrey-200: #e1e6ee;
        --color-toothpaste: #b6f0ff;
        --color-ice: #f1f9ff;
        --color-salmon: #ffefef;
        --color-red-200: #ffd1d1;
        --color-watermelon: #ff5844;
        --color-bluegray-300: #c3ccd9;
        --color-bluegray-500: #71717a;
        --color-gray-500: #767676;

        display: flex;
        flex-flow: column nowrap;
        height: 100%;

        .Calendar-headerTitle {
            display: flex;
            flex-flow: column nowrap;
            justify-content: center;
            align-items: center;
            width: 100%;
            font-size: 1.4rem;
            font-weight: 600;

            @media (width >= 850px) {
                flex-flow: row nowrap;
                justify-content: space-between;
                padding-left: 26%;
            }
        }

        .Calendar-headerTitleText {
            width: fit-content;

            &:empty {
                display: none;
            }
        }

        .Calendar-resetButton {
            color: var(--f-blue-600);
            font-weight: 600;
            font-size: 1.2rem;
            width: fit-content;

            &:disabled {
                color: var(--f-bluegray-300);
                cursor: not-allowed;
            }

            &:hover:not(:disabled) {
                text-decoration: underline;
            }
        }

        .Calendar-nav {
            display: flex;
            place-content: center space-between;
            align-items: center;
            width: 100%;
            margin-bottom: 0.75rem;
        }

        .Calendar-navButton {
            display: flex;
            place-content: center center;
            align-items: center;
            height: 36px;
            width: 36px;
            border-radius: 50%;
            border: $border-width solid transparent;

            &:disabled {
                opacity: 0.5;
                cursor: not-allowed;
            }

            &:focus,
            &:hover:not(:disabled) {
                border-radius: 50%;
                background-color: var(--color-blue-50);
                border-color: var(--color-primary-blue);
                outline: none;
            }

            &:active:not(:disabled) {
                border-radius: 50%;
                background-color: var(--f-blue-100);
            }
        }

        .Calendar-columnTitleList {
            display: flex;
            flex-flow: row nowrap;
        }

        .Calendar-columnTitleItem {
            font-size: 1.3rem;
            font-weight: 300;
            text-align: center;
            width: calc(100% * (1 / 7));
        }

        .Calendar-columnTitleText {
            text-decoration: none;
        }

        .Calendar-calendar {
            display: flex;
            flex-flow: row wrap;
            gap: 2px 0;
        }

        .CalendarDay {
            border-radius: 50%;
            display: flex;
            flex-flow: row nowrap;
            font-weight: 700;
            place-content: center;
            align-items: center;
            width: calc(100% * (1 / 7));
            aspect-ratio: 1 / 1;
            border: solid 2px transparent;
            cursor: pointer;
            font-size: 1.4rem;
            transition: color 0.2s ease-in;

            &:focus {
                border-radius: 50%;
                outline: none;
                background-color: var(--color-blue-50);
                border-color: var(--color-primary-blue);
            }

            &:nth-child(7n + 1) {
                border-top-left-radius: 50%;
                border-bottom-left-radius: 50%;
            }

            &:nth-child(7n) {
                border-top-right-radius: 50%;
                border-bottom-right-radius: 50%;
            }

            &:hover:not(:disabled) {
                background-color: var(--color-blue-50);
            }

            &:active:not(:disabled) {
                border-radius: 50%;
            }
        }

        .CalendarDay--isNotDisplayedMonth {
            visibility: hidden;
        }

        .CalendarDay--eventSelect {
            opacity: 1;
            background-color: var(--color-primary-blue);
            color: var(--color-white);

            &:focus,
            &:hover:not(:disabled) {
                background-color: var(--color-primary-blue);
                color: var(--color-white);
            }
        }

        .CalendarDay--eventSelectStart {
            position: relative;
            z-index: 1;

            @mixin start-item-background var(--color-primary-blue), var(--color-toothpaste);
        }

        .CalendarDay--eventSelectBetween {
            background-color: var(--color-toothpaste);
            border-radius: 0;
            color: inherit;

            &:focus,
            &:hover:not(:disabled) {
                background-color: var(--color-toothpaste);
                color: inherit;
            }
        }

        .CalendarDay--eventSelectEnd {
            position: relative;
            z-index: 1;

            @mixin end-item-background var(--color-primary-blue), var(--color-toothpaste);
        }

        .CalendarDay--eventBooking {
            font-weight: 400;
            outline: none;
            background-color: var(--color-red-200);
            border-color: transparent;
            cursor: not-allowed;

            &:focus,
            &:hover:not(:disabled) {
                background-color: var(--color-red-200);
            }
        }

        .CalendarDay--eventBookingStart:not(.CalendarDay--eventBookingEnd) {
            @mixin start-item-background var(--color-red-200), var(--color-red-200);
        }

        .CalendarDay--eventBookingBetween,
        .CalendarDay--eventBookingStart.CalendarDay--eventBookingEnd {
            border-radius: 0;
        }

        .CalendarDay--eventBookingEnd:not(.CalendarDay--eventBookingStart) {
            @mixin end-item-background var(--color-red-200), var(--color-red-200);
        }

        .CalendarDay-content {
            display: none;
        }

        .CalendarDay--eventMinimumRentalDuration {
            font-weight: 400;
            color: var(--color-gray-500);
            position: relative;

            &:hover,
            &:active {
                background-color: var(--color-bluegray-300);
                text-decoration: none;

                @mixin popup "Minimum " attr(data-requirement-minimum-rental-duration) " netter";
            }
        }

        .CalendarDay--eventInvalidCheckinDay {
            font-weight: 400;
            color: var(--color-gray-500);
            position: relative;

            &:hover,
            &:active {
                background-color: var(--color-bluegray-300);
                text-decoration: none;

                @mixin popup "Ingen innsjekk";
            }
        }

        .CalendarDay--eventInvalidCheckoutDay {
            font-weight: 400;
            color: var(--color-gray-500);
            position: relative;

            &:hover,
            &:active {
                background-color: var(--color-bluegray-300);
                text-decoration: none;

                @mixin popup "Ingen utsjekk";
            }
        }

        .CalendarDay--isExpired,
        .CalendarDay--eventExpired {
            cursor: not-allowed;
            font-weight: 400;
            opacity: 0.35;
            text-decoration: line-through;
        }

        .CalendarDay--isFirstDayOfMonth {
            border-top-left-radius: 50%;
            border-bottom-left-radius: 50%;
        }

        .CalendarDay--isLastDayOfMonth {
            border-top-right-radius: 50%;
            border-bottom-right-radius: 50%;
        }
    }
}
