@media (width <= 767px) {
    .objectPageMapContainer finn-map {
        padding-top: 100%;
    }
}

@media (width >= 768px) {
    .objectPageMapContainer finn-map {
        padding-top: 66%;
    }
}
