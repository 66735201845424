.close-button {
    display: flex;
    align-self: flex-end;
    justify-content: center;
    align-items: center;
    min-height: 3.5rem;
    min-width: 3.5rem;
    border: 2px solid transparent;
    border-radius: 50%;
    cursor: pointer;
    z-index: 1;

    &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
    }

    &:focus:not(:disabled),
    &:hover:not(:disabled) {
        border-radius: 50%;
        background-color: var(--f-blue-50);
        border-color: var(--f-blue-600);
        outline: none;
    }

    &:active:not(:disabled) {
        border-radius: 50%;
        background-color: var(--f-blue-100);
        border-color: var(--f-blue-600);
    }

    svg {
        pointer-events: none;
    }
}
