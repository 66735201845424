.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #ffffff1a;
    backdrop-filter: blur(3px);
    z-index: 2;
}

.floating-panel {
    position: sticky;
    z-index: 2;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    background-color: var(--f-white);
    padding: 1rem;
    max-width: 100vw;
    width: 100%;
    -webkit-overflow-scrolling: touch;
    box-shadow: 2px 2px 12px #6f899fb3;

    &--closed {
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;
        padding: 1rem 1.5rem;
        border-radius: 8px;
        bottom: 1rem;

        p {
            margin: 0;
            font-size: 1.2rem;
        }
    }

    &--open {
        position: fixed;
        flex-flow: column nowrap;
        border-radius: 25px 25px 0 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        padding: 4rem 2rem 0.75rem;
        height: auto;
        overflow-y: auto;
        max-height: calc(100% - 12rem - env(safe-area-inset-bottom));
    }

    &:focus,
    &:focus-within {
        outline: none;
    }
}

.selected-dates-view {
    display: flex;
    flex-flow: column nowrap;
    width: fit-content;
}

.price {
    font-size: 1.2rem;

    &__number {
        text-decoration: underline;
    }
}

.date-button {
    text-decoration: underline;
    font-size: 1.2rem;
}

.close-button-container {
    position: fixed;
    display: flex;
    justify-content: flex-end;
    margin-top: -4rem;
    margin-left: -2rem;
    width: 100%;
    background: linear-gradient(180deg, #fff 0%, #fff 50%, #ffffffc9 70%, #fff0 100%);
    border-radius: 25px 25px 0 0;
    z-index: 2;
    padding: 0.25rem 0.75rem 0 0;
}

.toggle-modal-button:focus {
    outline: none;
}
