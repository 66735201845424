.profilePublic {
    align-items: center;
    position: relative;
    border: 0;
    border-radius: 8px;
    display: flex;
}

.avatar {
    display: block; /* Needed to set width for ie10 */
    position: relative;
    align-self: flex-start;
    flex-shrink: 0;
    flex-grow: 0;
    width: 64px;
    height: 64px;
    margin-right: 16px;
}

.avatarImg {
    position: relative;
    z-index: 1;
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    overflow: hidden;
}

.profileLink {
    text-decoration: none;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    display: block;
}

.profileLink:hover {
    text-decoration: underline;
}

.profileLink:active {
    text-decoration: underline;
}

.profileLink:focus {
    text-decoration: underline;
}

.profilePublic .profileLink::before {
    content: "";
    position: absolute;
    display: block;
    inset: 0;
    z-index: 1;
}

.profilePublic a:focus {
    outline: none;
}

.profilePublic:focus-within {
    outline: 2px solid #06befb;
}

.container {
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 8px;
    min-width: 0;
}

.heading {
    height: 1px;
    left: -10000px;
    overflow: hidden;
    position: absolute;
    top: auto;
    width: 1px;
}

.link {
    display: flex;
    margin-bottom: -4px;
}

.identity {
    align-items: center;
    color: #474445;
    display: flex;
    hyphens: none;
    overflow-wrap: anywhere;
}
