.list {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin: 0;
    padding: 0;
}

.item {
    align-items: center;
    background-color: #f1f9ff;
    border-radius: 8px;
    color: #47474f;
    display: flex;
    font-size: 12px;
    gap: 5px;
    padding: 4px 8px;
}
