.recommendationItem {
    z-index: 2;
    position: relative;
}

.recommendationItem a::before {
    content: "";
    position: absolute;
    display: block;
    inset: -200px 0 0;
}

.recommendationItem a:focus {
    outline: none;
}

.recommendationItem:focus-within {
    outline: 2px solid #06befb;
}
